import { Component } from 'react';
import ClassesService, { IDaySchedule } from 'services/classes.service';
import React from 'react';
import moment from 'moment';
 
class ClassesComponent extends Component {
    classesService = new ClassesService();

    state =  {
        currentTime: moment(),
        currentDay: moment().format('dddd'),
        schedule: this.classesService.getSchedule(),
    };

    private _buildSchedule(days: IDaySchedule[]) {
        return days.map(daySchedule => {
            return (
                <div className="col-md-6 col-lg-3 mb-5" key={daySchedule.days.join()}>
                    <div className="position-relative">
                        <div className="position-relative font-family-alt font-weight-700 letter-spacing-1 text-extra-large text-uppercase">
                            { 
                                daySchedule.days.map((dayName, index) => {
                                    const isToday = dayName === this.state.currentDay ? 'text-green' : 'text-secondary';
                                    return (
                                        <span className={`${isToday}`} key={dayName}>
                                            { index > 0 && <span className="mr-1 ml-1">/</span>}
                                            { dayName }
                                        </span>
                                    );
                                })
                            }
                        </div>
                        <div className="timeline sm-margin-5 margin-10 sm-no-margin-bottom no-margin-bottom sm-no-margin-rl no-margin-rl position-relative">
                            <ul className="border-left border-gray-light list-unstyled position-relative">
                                { this._buildScheduleItems(daySchedule) }
                            </ul>
                        </div>
                    </div>
                </div>
            );
        });
    }

    private _buildScheduleItems(daySchedule: IDaySchedule): any {
        const isToday = daySchedule.days.indexOf(this.state.currentDay) >= 0;

        return daySchedule.classes.map(scheduleItem => {
            const modifier = isToday && this.state.currentTime.isAfter(moment(this.state.currentTime.format('YYYY-M-D') + ' ' + scheduleItem.time, 'YYYY-M-D H:mmA')) ? 'highlight' : '';
            return (
                <li className={`timeline-item position-relative ${modifier}`} key={daySchedule.days.join() + scheduleItem.time}>
                    <div className="timeline-content bg-white border border-gray-light border-round display-block">
                        <span className="display-block font-family-alt letter-spacing-1 text-gray-dark text-small">{scheduleItem.time}</span>
                        <span className="display-block font-family-alt text-medium text-gray-dark-2">{scheduleItem.name}</span>
                        { scheduleItem.notes &&
                            <strong className="display-block font-family-alt text-small text-gray-dark-2">{scheduleItem.notes}</strong>
                        }
                    </div>
                </li>
            );
        });
    }
    
    render() {
        const { schedule } = this.state;

        return (
            <div className="container">
                <div className="row no-padding-rl no-padding-top padding-8">
                    <div className="col-md-8 offset-md-2 text-center">
                        <h3 className="font-family-alt font-weight-700 letter-spacing-2 text-uppercase xs-title-small title-medium">Class Schedule</h3>
                        <p className="font-family-alt margin-3 no-margin-bottom no-margin-rl text-gray-dark-2 text-large">Take a look at our training schedule. We hope to see you soon! Additionally, all teammates are welcomed and encouraged to cross-train at any of our affiliate gyms.</p>
                    </div>
                </div>
                <div className="row">
                    { this._buildSchedule(schedule) }
                </div>
            </div>
        );
    }
}

export default ClassesComponent;
