import React from 'react';
import moment from 'moment';
import ClassesService from 'services/classes.service';

export default class NextClassCountdown extends React.Component {
    classesService = new ClassesService();

    interval: any;
    state = {
        output: undefined,
        outputClass: undefined
    };

    componentDidMount() {
        this._setInterval();
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    private _setInterval(): void {
        let nextClassTime: moment.Moment = moment();
        
        this.interval = setInterval(() => {
            const now = moment();
            if (now > moment(nextClassTime)) {
                nextClassTime = moment(this.classesService.getNextClass(now.toDate()));
            }

            let output: string = '';
            const difference = moment.duration(nextClassTime.diff(now));
            output += this._formatTime(Math.floor(difference.asDays()), 'd');
            output += this._formatTime(Math.floor(difference.hours()), 'h');
            output += this._formatTime(Math.floor(difference.minutes()), 'm');
            output += this._formatTime(Math.floor(difference.seconds()), 's');

            this.setState({ output: output });
        }, 1000);
    }

    private _formatTime(inputNumber: number, letter: string): string {
        return inputNumber ? inputNumber + letter + ' ' : '';
    }

    render() {
        const { output, outputClass } = this.state;

        return (
            <span className={outputClass}>{output}</span>
        );
    }
}