import React, { Component } from 'react';

// TODO: Change this to the Naptown Logo
import logoLight from 'assets/images/naptown_logo.png';
import logoDark from 'assets/images/naptown_logo_dark.png';

class NavbarComponent extends Component {
  props!: { isScrolled: boolean };

  render() {
    const { isScrolled } = this.props;
    const navbarClass = isScrolled ? 'shrink' : '';

    return (
      <nav id="navigation" className={`navbar navbar-expand-lg fixed-top navbar-white navbar-light ${navbarClass}`}>
        <div className="container">
          <div className="row justify-content-between">
            <div className="navbar-header d-flex col-12 col-lg-3 justify-content-between">
              <a className="navbar-brand page-scroll font-family-alt letter-spacing-1 text-extra-large text-uppercase mb-2" href="#home">
                <img className="logo-navbar-dark" src={logoLight} alt="" />
                <img className="logo-navbar-white" src={logoLight} alt="" />
              </a>
              <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div id="navbarSupportedContent" className="navbar-collapse collapse justify-content-end">
              <ul className="nav navbar-nav font-family-alt letter-spacing-1 text-small text-uppercase">
                <li><a href="#home" className="page-scroll">Home</a></li>
                <li><a href="#about" className="page-scroll">About</a></li>
                <li><a href="#coaches" className="page-scroll">Coaches</a></li>
                <li><a href="#schedule" className="page-scroll">Schedule</a></li>
                <li><a href="#contact" className="page-scroll">Contact</a></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavbarComponent;
