import React, { Component } from 'react';
import logo from 'assets/images/naptown_logo.png';
import moment from 'moment';

class FooterComponent extends Component {
    render() {
        const currentYear: string = moment().format('YYYY');
        return (
            <footer className="footer bg-white-2 pb-0">
                <div className="container border-top pt-4">
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="footer-logo xs-text-center pb-4">
                                <img src={logo} alt="logo" className="opacity-8 p-0 mb-2" />
                                <div className="footer-copyright p-0">
                                    <span className="font-family-alt letter-spacing-1 text-small text-uppercase">© {currentYear} Naptown Brazilian Jiu-Jitsu &amp; Boxing. Site by E.DeWhitt.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default FooterComponent;
