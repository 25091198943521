import React, { Component } from 'react';
import NavbarComponent from 'components/navbar';
import 'styles/index.scss';
import FooterComponent from 'components/footer';
import { Scrollbars } from 'react-custom-scrollbars';

class CoreTemplate extends Component {
    state = { isScrolled: false };

    onUpdate(scrollEvent: any): void {
        this.setState({ isScrolled: scrollEvent.top > .002 });
    }

    render() {
        return (
            <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                onUpdate={(event) => this.onUpdate(event)}>
                <div className='app-core'>
                    <NavbarComponent isScrolled={this.state.isScrolled} />
                    {this.props.children}
                    <FooterComponent />
                </div>
            </Scrollbars>
        );
    }
}

export default CoreTemplate;
