import React, { Component } from 'react';
import ronelJpeg from 'assets/images/ronel.jpeg';
import boardJpg from 'assets/images/board.jpg';
import chaseJpeg from 'assets/images/chase.jpeg';
import daveJpeg from 'assets/images/dave.jpeg';

interface ICoach {
    name: string;
    title: string;
    image: string;
    description: string;
    showFullDescription?: boolean;
}

const coaches: ICoach[] = [
    {
        name: 'Eric Board',
        title: 'Head Coach',
        image: boardJpg,
        description: 'Eric is a Black Belt in Brazilian Jiu Jitsu under Professor Jason Kesler and is very proud to have opened Fishers BJJ and Boxing’s first affiliate gym! Achievements include being a 9-time IBJJF international Open Gold medalist, 11-time Expert division NAGA champion and an 8-time expert division Newbreed Champion. Eric has trained and competed all over the world with blessed success. In his 13th year of Jiu-Jitsu Eric is pumped to open the doors at Naptown Brazilian Jiu Jitsu & Boxing and share his experience with the Naptown BJJ team.',
    },
    {
        name: 'Chase Bruton',
        title: 'Jiu-Jitsu Coach',
        image: chaseJpeg,
        description: 'Chase is a jiu jitsu black belt under Coach Eric Board and a judo brown belt. An avid competitor, Chase has grappled in multiple IBJJF, Fuji, Newbreed, Grappling Industries, and NAGA tournaments.',
    },
    {
        name: 'Dave',
        title: 'Jiu-Jitsu Coach',
        image: daveJpeg,
        description: 'Dave started training Jiu Jitsu in 2009 while finishing a night school program. Years spent balancing work and school left little time for fitness related pursuits, and his goal was to get back in shape. The appeal of martial arts revolved around a hope that training alongside a group of peers would make exercise more interesting and sustainable i.e. he would be less likely to skip! Knowing nothing about martial arts, Dave did a little research and took a chance on an intriguing, grappling based martial art called Brazilian Jiu Jitsu. Venturing onto the Jiu Jitsu mats proved to be a life altering decision for Dave, and the worry of finding a sustainable workout dissolved. Jiu Jitsu quickly dominated all of his free time: week nights spent training until 10pm, weekends spent traveling to other schools, tournaments, or seminars, and a great deal of time spent hanging out with a tight knit group of training partners. Dave considers it a privilege to have trained under and alongside many exceptional Jiu Jitsu players over the years. After moving around a bit he has now settled in Indianapolis, and again feels it a privilege to be part of the Jiu Jitsu community of Indiana and the Naptown BJJ school.'
    }
]

class CoachesComponent extends Component {

    state = { coaches: coaches };

    private _buildDescription(coach: ICoach) {
        const shortDescription = coach.description.split(' ').slice(0, 20).join(' ');

        if (coach.showFullDescription && coach.description.length > shortDescription.length) {
            return (<p className="coach-description">{coach.description}</p>);
        }

        return (
            <div>
                <span className="d-block mb-2">{shortDescription}...</span>
                <button type="button" className="d-block btn btn-link" onClick={() => this._toggleDetails(coach)}>
                    Learn More
                    {/* <i className="ml-2 arrow right"></i>
                    <i className="arrow right"></i> */}
                </button>
            </div>
        )
    }

    private _buildCoaches() {
        return this.state.coaches.map(coach => {
            return (
                <div className="col-sm-6 col-lg-4" key={coach.name}>
                    <div className="coach">
                        <div className="coach-image">
                            <img src={coach.image} alt={coach.name} className="img-fluid" />
                        </div>
                        <div className="coach-details">
                            <div className="content"> 
                                <h4>{coach.name}</h4>
                                <label>{coach.title}</label>
                                {this._buildDescription(coach)}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    private _toggleDetails(selectedCoach: ICoach) {
        this.setState(() => ({
            coaches: this.state.coaches.map(coach => {
                if (coach.name === selectedCoach.name) {
                    selectedCoach.showFullDescription = !!!selectedCoach.showFullDescription;
                }

                return coach;
            })
        }));
    }

    render() {
        return (
        <div className="container">
            <div className="row">
                <div className="text-center col-md-8 offset-md-2 mb-5">
                    <h3 className="font-family-alt font-weight-700 letter-spacing-2 text-uppercase xs-title-small title-medium title">
                        Coaches
                    </h3>
                    <p className="font-family-alt margin-3 no-margin-bottom no-margin-rl text-gray-dark-2 text-large">Our coaches at Naptown BJJ are some of the best in the business. Come in, meet the team and see why Naptown BJJ is the best place to practice BJJ and Boxing in Indianapolis.</p>
                </div>
            </div>
            <div className="row coaches">
                {this._buildCoaches()}
            </div>
        </div>
      );
    }
}

export default CoachesComponent;
