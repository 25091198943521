import { Component } from 'react';
import React from 'react';

class ContactUsComponent extends Component {

    private _buildContactForm() {
        return {
            __html: `<!-- Begin Mailchimp Signup Form -->
            <div id="mc_embed_signup">
            <form action="https://naptownbjj.us4.list-manage.com/subscribe/post?u=7518e4267fb11e9b034624066&amp;id=9c21c4e757" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                <div id="mc_embed_signup_scroll">
                
            <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
            <div class="mc-field-group">
                <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
            </label>
                <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
            </div>
            <div class="mc-field-group">
                <label for="mce-FNAME">First Name </label>
                <input type="text" value="" name="FNAME" class="" id="mce-FNAME">
            </div>
            <div class="mc-field-group">
                <label for="mce-LNAME">Last Name </label>
                <input type="text" value="" name="LNAME" class="" id="mce-LNAME">
            </div>
            <div class="mc-field-group size1of2">
                <label for="mce-PHONE">Phone Number  <span class="asterisk">*</span>
            </label>
                <input type="text" name="PHONE" class="required" value="" id="mce-PHONE">
            </div>
            <div class="mc-field-group">
                <label for="mce-COMMENT">Question/Comment </label>
                <input type="text" value="" name="COMMENT" class="" id="mce-COMMENT">
            </div>
                <div id="mce-responses" class="clear">
                    <div class="response" id="mce-error-response" style="display:none"></div>
                    <div class="response" id="mce-success-response" style="display:none"></div>
                </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_7518e4267fb11e9b034624066_9c21c4e757" tabindex="-1" value=""></div>
                <div class="clear"><input type="submit" value="Submit" name="Submit" id="mc-embedded-subscribe" class="btn btn-outline-black btn-medium"></div>
                </div>
            </form>
            </div>`
        }
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                <div className="col-md-6 mb-5">
                    <h2 className="font-family-alt sm-title-large title-extra-large-2 text-gray-dark-2">Ready to Get Started?</h2>
                    <p className="font-family-alt margin-5-5 no-margin-rl sm-text-large text-extra-large text-gray-dark-2">Are you ready to schedule your trial class? Would you like to learn more about us? Send us a message or give us a call.</p>
                    <div className="row">
                        

                        <label className="col-3 display-block font-family-alt font-weight-700 letter-spacing-1 text-medium text-uppercase">
                            Phone:
                        </label>
                        <p className="col-9 display-block margin-2 no-margin-top no-margin-rl text-gray-dark-2 xs-text-large title-small">
                            (270) 855-0566
                        </p>

                        <label className="col-3 display-block font-family-alt font-weight-700 letter-spacing-1 text-medium text-uppercase">
                            Email:
                        </label>
                        <p className="col-9 display-block margin-2 no-margin-top no-margin-rl text-gray-dark-2 xs-text-large title-small">
                            <a className="btn-link" href="mailto:naptownbjj@yahoo.com">naptownbjj@yahoo.com</a>
                        </p>

                        <label className="col-3 display-block font-family-alt font-weight-700 letter-spacing-1 text-medium text-uppercase">
                            Address:
                        </label>
                        <p className="col-9 display-block margin-2 no-margin-top no-margin-rl text-gray-dark-2 xs-text-large title-small">
                            500 N Meridian Street, STE 10B<br/>
                            Indianapolis, Indiana 46204
                        </p>
                    </div>
                </div>
                    {/* <div className="col-md-5 offset-md-1">
                        <div dangerouslySetInnerHTML={this._buildContactForm()}></div>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default ContactUsComponent;
