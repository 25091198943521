import React from 'react';
import AboutComponent from './about';
import ClassesComponent from './classes';
import CoachesComponent from './coaches';
import ContactUsComponent from './contact-us';
import NextClassCountdown from 'components/next-class-countdown';

const HomeComponent: React.FC = () => {
  return (
    <div>
      <section id="home" className="flex-column bg-cover bg-gray-dark-2 bg-overlay-black-8 d-flex no-padding width-100 text-white">
        <div className="d-flex flex-grow-1 justify-content-center align-items-center align-self-center width-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-11 col-lg-9 text-center">
                <div className="font-family-alt font-weight-700 mb-3 letter-spacing-2 text-uppercase">
                  <h1 className="xs-title-extra-large-2 sm-title-extra-large-5">
                    Naptown
                  </h1>
                  <h2 className="xs-title-small sm-title-large title-extra-large-1">
                    Brazilian Jiu-Jitsu &amp; Boxing
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-bottom align-self-bottom mb-5">
          <div className="row">
            <div className="col text-center">
              <h6 className="mb-3">
                Next class starting in&nbsp;
                <NextClassCountdown />
              </h6>
              <p>
                <i className="arrow arrow-lg down"></i>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="bg-white-2">
        <AboutComponent />
      </section>
      <section id="coaches" className="bg-white-2">
        <CoachesComponent />
      </section>
      <section id="schedule" className="bg-white-3">
        <ClassesComponent />
      </section>
      <section id="contact" className="bg-white-2">
        <ContactUsComponent />
      </section>
    </div>
  );
}

export default HomeComponent;
