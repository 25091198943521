import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

/* Components */
import CoreTemplate from 'components/core-template';
import HomeComponent from 'components/app-pages/home';
import UnderConstructionComponent from 'components/app-pages/under_construction';

const routing = (
    <Router>
      <Switch>
        <Route path="/under-construction" component={UnderConstructionComponent} />
        <CoreTemplate>
          <Route path="/" exact component={HomeComponent} />
          <Route path="/Home" component={HomeComponent} />
        </CoreTemplate>
      </Switch>
    </Router>
  );

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
