import React from 'react';
import FishersLogo from 'assets/images/fishers_bjj_boxing_logo.png';
import IbjjaLogo from 'assets/images/ibjja.png';
import LiftLabLogo from 'assets/images/lift_lab_logo.jpg';

const AboutComponent: React.FC = () => {
  return (
    <div className="container">
        <div className="row justify-content-between">
            <div className="col-lg-7 mb-5">
                <div className="mb-3 font-family-alt">
                    <h4 className="font-weight-700 xs-title-small">Students gain the fundamental</h4>
                    <h2 className="text-primary text-uppercase padding-2 no-padding-rl xs-title-extra-large-2">
                        Skills &amp;
                        <br/>
                        Experience
                    </h2>
                    <h4 className="font-weight-700 xs-title-small">to improve confidence and defend themselves.</h4>
                </div>
                <div className="">
                <p className="font-family-alt text-extra-large">Visit us and see why Brazilian Jiu Jitsu is one of the fastest growing sports in the world.</p>
                <p className="text-large text-gray-dark-2">Naptown Brazilian Jiu Jitsu and Boxing was established in 2019 led by Black Belt, Professor Eric Board. Naptown prides itself on providing the Indianapolis area a high-quality, safe place to learn, practice and excel in BJJ. Our style of training can be adapted to any level and is based on techniques that can easily be applied in competition or in real-life self defense scenarios.</p>
                <p className="text-large text-gray-dark-2">We provide training and guidance for everyone, regardless of experience or skill level. Whether you are a newcomer to BJJ or an experienced practioner, we’re excited to hit the mats with you!</p>
                <p className="text-large text-gray-dark-2">Naptown BJJ is a proud affiliate of Fishers BJJ and Boxing under Jason Kesler and Indiana Brazilian Jiujitsu Academy under James Clingerman.</p>
                <a href="#schedule" className="page-scroll btn btn-outline-black btn-medium margin-6-5 no-margin-bottom no-margin-rl">Check Schedule</a>
                </div>
            </div>
            <div className="col-lg-4 text-center">
                <div className="row">
                    <div className="mb-5 col-sm-6 col-lg-12">
                        <h5 className="font-weight-700 letter-spacing-1 text-uppercase mb-3">
                            In partnership with
                        </h5>
                        <a href="http://www.liftlabco.com" target="_blank" rel="noopener noreferrer">
                            <img className="img-fluid" src={LiftLabLogo} alt="Lift Lab Co." />
                        </a>
                    </div>
                    <div className="col-sm-6 col-lg-12">
                        <h5 className="font-weight-700 letter-spacing-1 text-uppercase mb-3">
                            A proud of affliate of
                        </h5>
                        <div className="row">
                            <div className="col-6">
                                <a href="http://fishersbjj-boxing.com" target="_blank" rel="noopener noreferrer">
                                    <img className="img-fluid" src={FishersLogo} alt="Fishers Brazilian Jiu-Jitsu and Boxing" />
                                </a>
                            </div>
                            <div className="col-6">
                                <a href="http://www.ibjja.com" target="_blank" rel="noopener noreferrer">
                                    <img className="img-fluid" src={IbjjaLogo} alt="Indiana Brazilian Jiu-Jitsu Academy" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default AboutComponent;
