import React from 'react';
import 'styles/under_construction.scss';
import logo from 'assets/images/logo-2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";

const UnderConstructionComponent: React.FC = () => {
  return (
    <div className='construction-wrapper'>
      <div className='construction-container'>
        <div className='container'>
          <section className='row'>
            <div className='d-none d-md-block col-md-3 offset-lg-2'>
              <img className='img-fluid' alt='Naptown BJJ' src={logo} />
            </div>
            <div className='col-md-9 col-lg-6 align-self-center'>
              <h4>NaptownBjj.com is</h4>
              <h2 className='text-warning'>UNDER CONSTRUCTION</h2>
              <p>Follow us on <a href='https://www.facebook.com/pg/Naptown-Brazilian-Jiu-Jitsu-Boxing-108728170517392/about/?ref=page_internal'>Facebook</a> to stay up to date with the latest news and upcoming events. For more information, email us at <a href='mailto:naptownbjj@yahoo.com'>naptownbjj@yahoo.com</a>.</p>
            </div>
          </section>
          <section>
            <div className='col-12 text-center'>
              <a className='btn btn-link' href='https://www.facebook.com/pg/Naptown-Brazilian-Jiu-Jitsu-Boxing-108728170517392/about/?ref=page_internal'>
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
              |
              <a className='btn btn-link' href='mailto:naptownbjj@yahoo.com'>naptownbjj@yahoo.com</a>
              |
              <a className='btn btn-link' href='tel:2708550566'>+1 (270) 855-0566</a>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default UnderConstructionComponent;
