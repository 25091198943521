import moment from "moment";

export interface IClassDetails { 
    name: string;
    time: string;
    notes?: string;
}

export interface IDaySchedule {
    days: string[];
    classes: IClassDetails[];
}

const schedule: IDaySchedule[] = [
    {
        days: ['Monday', 'Wednesday'],
        classes: [
            { name: 'Adult Gi BJJ', time: '12:00 PM' },
            { name: 'Gi Open Mat', time: '1:00 PM' },
            { name: 'Kids BJJ', time: '4:30 PM', notes: 'Monday No Gi, Wednesday Gi' },
            { name: 'Kicboxing', time: '5:15 PM' },
            { name: 'Adult Gi BJJ', time: '6:15 PM' },
            { name: 'Gi Open Mat', time: '7:00 PM' },
        ],
    },
    {
        days: ['Tuesday'],
        classes: [
            { name: 'Gi Drilling', time: '6:00 AM' },
            { name: 'Adult No Gi BJJ', time: '12:00 PM' },
            { name: 'No Gi Open Mat', time: '1:00 PM' },
            { name: 'Kids No Gi BJJ', time: '4:30 PM'},
            { name: 'Kickboxing', time: '5:15 PM' },
            { name: 'Adult No Gi BJJ', time: '6:15 PM' },
            { name: 'No Gi Open Mat', time: '7:00 PM' },
        ],
    },
    {
        days: ['Thursday'],
        classes: [
            { name: 'Cross Training Open Mat', time: '12:00 PM' },
            { name: 'Kids Gi BJJ', time: '4:30 PM', },
            { name: 'Kickboxing', time: '5:15 PM' },
            { name: 'Adult Gi BJJ', time: '6:15 PM' },
            { name: 'Gi Open Mat', time: '7:00 PM' },
        ],
    },
    {
        days: ['Friday'],
        classes: [
            { name: 'Adult No Gi BJJ', time: '12:00 PM' },
            { name: 'No Gi Open Mat', time: '1:00 PM' },
        ],
    },
    {
        days: ['Saturday'],
        classes:  [
            { name: 'Kids Gi Comp Training', time: '10:00 AM' },
            { name: 'Gi Comp Training', time: '11:00 AM' },
        ],
    },
    {
        days: ['Sunday'],
        classes:  [
            { name: 'Wrestling', time: '11:00 AM' },
            { name: 'No Gi Comp Traning', time: '12:00 PM' },
        ],
    }
];

export default class ClassesService {
    getTodaysSchedule(): IDaySchedule | undefined {
        const today = moment().format('dddd');
        return schedule.find(x => x.days.indexOf(today) >= 0);
    }

    getNextClass(currentTime: Date): Date {
        let currentMoment = moment(currentTime);
        let nextClassTime: Date | undefined;

        while (!nextClassTime) {
            let today = currentMoment.format('dddd');
            const currentSchedule = this._getClassesForDay(today);

            if (currentSchedule) {
                nextClassTime = this._getNextClassTime(currentTime, currentMoment.toDate(), currentSchedule);
            }

            if (!nextClassTime) {
                currentMoment.add(1, 'd');
            }
        }

        return nextClassTime;
    }

    getSchedule(): IDaySchedule[] {
        return schedule;
    }

    _getClassesForDay(dayName: string): IDaySchedule | undefined {
        return schedule.find(x => x.days.indexOf(dayName) >= 0);
    }

    _getNextClassTime(currentTime: Date, day: Date, daySchedule: IDaySchedule): Date | undefined {
        const currentMoment = moment(currentTime);
        let nextClassTime: moment.Moment | undefined;
        daySchedule.classes.forEach(classSchedule => {
            const classTime = moment(`${moment(day).format('MM-DD-YYYY')} ${classSchedule.time}`, 'MM-DD-YYYY h:mm AA');
            if (classTime > currentMoment && (!nextClassTime || classTime < nextClassTime)) {
                nextClassTime = classTime; 
            }
        });

        return nextClassTime ? nextClassTime.toDate() : undefined;
    }
}